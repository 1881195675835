<template>
  <div class="h-100 sidebar-account">
    <!-- <div>
      <TheTitleBar
        sectionTitle="dashboard.label.mnuMyAccount"
        sectionID="dashboard.label.mnuMyAccount"
        :showCount="false"
        :showValue="false"
        :showFilters="false"
        :showSort="false"
        :showSellButton="false"
      />
    </div> -->
    <b-row class="py-3 py-md-4 mx-0">
      <b-col class="benefits rounded mr-3 col-md-3 col-12 p-3">
        <!-- <div class="text-center p-4">
          <p class="text-capitalize" v-if="user.FirstName && user.LastName">
            {{ user.FirstName }} {{ user.LastName }}
          </p>
          <p class="pt-2 text-half-dark">{{ user.Email }}</p>
        </div> -->

        <router-link :to="{ name: 'PrimaryContact' }" class="routerLink">
          <b-list-group-item
            button
            :active="isActive('myprofile')"
            class="d-flex align-items-center border-0"
          >
            <span class="mr-auto">{{ $t("dashboard.label.accountProfile") }}</span>
          </b-list-group-item></router-link
        >
<!--
        <router-link :to="{ name: 'UserType' }" class="routerLink">
          <b-list-group-item
            button
            :active="isActive('userType')"
            class="d-flex align-items-center border-0"
          >
            <span class="mr-auto">{{$t("dashboard.label.UserType")}}</span>
          </b-list-group-item></router-link
        >
        <router-link :to="{ name: 'Parameters' }" class="routerLink">
          <b-list-group-item
            button
            :active="isActive('parameters')"
            class="d-flex align-items-center border-0"
          >
            <span class="mr-auto">{{$t("dashboard.label.Parameters")}}</span>
          </b-list-group-item></router-link
        >
        <router-link :to="{ name: 'Security' }" class="routerLink">
          <b-list-group-item
            button
            :active="isActive('security')"
            class="d-flex align-items-center border-0"
          >
            <span class="mr-auto">{{ $t("dashboard.label.accountSecurity") }}</span>
          </b-list-group-item></router-link
        >
-->
      </b-col>
      <b-col class="my-0">
        <router-view></router-view>
        <b-alert
          variant="success"
          :show="$route.path === '/dashboards/profile/myprofile'"
          dismissible
          class="alert-box success-box"
        >
          <h5>
            <b-avatar class="avatar"><b-icon-shield-check scale="0.8" /></b-avatar>
            <b class="px-2">{{$t("ListingProcess.ContactWarnTitle")}}</b>
          </h5>
          <p v-html='$t("ListingProcess.ContactWarnDesc")' />
        </b-alert>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// import TheTitleBar from "@/components/dashboard/global/TheTitleBar.vue";

import { mapGetters } from "vuex";

export default {
  components: {
    // TheTitleBar
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    isActive(path) {
      return this.$route.path.indexOf(path) !== -1;
      localStorage.setItem("subMenu", "BuyerDashboard")
    },
  },
};
</script>
<style lang="scss" scoped>
.list-group-item {
  border-left: 5px solid rgba(34, 119, 239, 0) !important;
}

.list-group-item.active {
  border-left: 5px solid #006ac3 !important;
  background-color: #e6f0f9 !important;
  color: #006ac3;
  font-weight: bold;
}
.sidebar-account {
  .list-group-item {
    border-radius: 4px;
    overflow: hidden;
    padding: 8px 16px;
    font-weight: 400;
    font-size: 16px;
    font-family: "Inter", sans-serif !important;
    margin-bottom: 8px;
  }
}
</style>

<style lang="scss">
.sidebar-account {
  .alert-box {
    border: none;
    display: flex;
    align-items: flex-start;
    min-height: 72px;
    padding-top: 21px !important;
    padding-bottom: 21px !important;
    padding-left: 40px !important;
    flex-direction: column;
    margin-top: 24px;
    p {
      color: #111111;
      margin-top: 16px;
    }
    b {
      font-weight: 600;
      color: #111;
      font-family: "Inter", sans-serif;
    }
    &.success-box {
      background: #dbf0d9;
    }
    h5 {
      font-weight: 500;
      font-size: 16px;
      margin-bottom: 0;
    }
    .close {
      position: absolute;
      right: 20px;
      z-index: 2;
      padding: 0.75rem 1.25rem;
      top: 50% !important;
      transform: translateY(-50%);
      font-size: 40px;
      font-weight: 200;
      color: #111;
      opacity: 1;
      padding: o;
    }
    .avatar {
      width: 24px;
      height: 24px;
      background: #4bb543;
      svg {
        fill: white;
      }
    }
  }
}
</style>
